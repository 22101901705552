<template>
  <div v-show="showVideo">
    <video ref="videoPlayer" class="video-js" />
  </div>
</template>

<script>
import 'assets/css/video.css';
import 'assets/css/video-theme.css';

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showVideo: false,
      player: null,
    }
  },
  mounted() {
    import('video.js').then((module) => {
      const videojs = module.default
      this.player = videojs(this.$refs.videoPlayer, this.options)
      this.showVideo = true
    }).catch((error) => {
      console.log(error)
    })
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    pause() {
      if (this.player) { this.player.pause() }
    },
    play() {
      if (this.player) { this.player.play() }
    },
  },
}
</script>
